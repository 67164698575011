import * as React from 'react';
import {
  VStack,
  Text,
  Heading,
  ListItem,
  OrderedList,
  Flex,
} from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const Terms: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Website Terms of Use');
  }, [mixpanel]);

  return (
    <Layout>
      <SEO title="Website Terms of Use" />
      <Flex direction="column" align="center" justify="center">
        <Heading size="lg" px={10} pt={10} textAlign="center">
          Canyou Website Terms of use
        </Heading>
        <VStack p={10} mx={5} spacing={4} maxWidth="900px">
          <Text>
            This website (Site) is operated by API Software Pty Ltd t/a Canyou
            Software (ABN: 57 650 764 195) (we, our or us). It is available at:
            https://canyou.com.au and may be available through other addresses
            or channels.
          </Text>

          <Heading size="md" textAlign="center">
            How you consent to these terms of use
          </Heading>
          <Text>
            If you access and/or use our Site, you are taken to have agreed to
            these terms of use (Terms). Please read these Terms carefully – if
            you don’t agree to them, then you must cease using our Site
            immediately.
          </Text>

          <Heading size="md" textAlign="center">
            When we make changes to these terms of use
          </Heading>
          <Text>
            We may, at any time and at our discretion, vary these Terms by
            publishing the varied terms on our Site. We recommend you check our
            Site regularly to ensure you are aware of our current terms.
            Materials and information on this Site (Content) are subject to
            change without notice. We do not undertake to keep our Site
            up-to-date and we are not liable if any Content is inaccurate or
            out-of-date.
          </Text>
          <Heading size="md" textAlign="center">
            Privacy
          </Heading>
          <Text>
            We respect your privacy and understand protecting your personal
            information is important. Our Privacy Policy (available on our Site)
            sets out how we will collect and handle your personal information.
          </Text>
          <Heading size="md" textAlign="center">
            Your licence to use our Site
          </Heading>
          <Text>
            We grant you a non-exclusive, royalty-free, revocable, worldwide,
            non-transferable licence to use our Site in accordance with these
            Terms. All other uses are prohibited without our prior written
            consent.
          </Text>
          <Heading size="md" textAlign="center">
            Conduct we don’t accept
          </Heading>
          <Text>
            When you use our Site, we expect you to abide by a certain standard
            of behaviour. You must not do or attempt to do anything that is
            unlawful, which is prohibited by any laws applicable to our Site,
            which we would consider inappropriate or which might bring us or our
            Site into disrepute. This includes:
            <OrderedList>
              <ListItem listStyleType="lower-latin">
                anything that would constitute a breach of an individual’s
                privacy (including uploading private or personal information
                without an individual&apos;s consent) or any other legal rights;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                using our Site to defame, harass, threaten, menace or offend any
                person;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                interfering with any user of our Site;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                tampering with or modifying our Site, knowingly transmitting
                viruses or other disabling features, or damaging or interfering
                with our Site, including (without limitation) using trojan
                horses, viruses or piracy or programming routines that may
                damage or interfere with our Site;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                using our Site to send unsolicited electronic messages; or
              </ListItem>
              <ListItem listStyleType="lower-latin">
                facilitating or assisting a third party to do any of the above
                acts.
              </ListItem>
            </OrderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Competitors are excluded from using our Site
          </Heading>
          <Text>
            You are prohibited from using our Site, including our Content, in
            any way that competes with our business.
          </Text>
          <Heading size="md" textAlign="center">
            Information
          </Heading>
          <Text>
            Please note that our Content is factual information only, is not
            comprehensive and is for general information purposes only. We use
            reasonable attempts to ensure the accuracy and completeness of our
            Content, we provide our Content in good faith, make no
            representation or warranty in relation to it, and are not liable for
            any loss arising from reliance on our Content.
          </Text>
          <Heading size="md" textAlign="center">
            Intellectual Property rights
          </Heading>
          <Text>
            Unless otherwise indicated, we own or licence all rights, title and
            interest (including intellectual property rights) in our Site, all
            of our Content and any copyright, registered or unregistered
            designs, patents or trade mark rights and domain names (Our
            Intellectual Property). Your use of our Site and your use of and
            access to any Content does not grant or transfer to you any rights,
            title or interest in relation to Our Intellectual Property. You must
            not:
            <OrderedList>
              <ListItem listStyleType="lower-latin">
                copy or use, in whole or in part, any of Our Intellectual
                Property;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                reproduce, retransmit, distribute, display, disseminate, sell,
                publish, broadcast or circulate any of Our Intellectual Property
                to any third party; or
              </ListItem>
              <ListItem listStyleType="lower-latin">
                breach any intellectual property rights connected with Our
                Intellectual Property, including (without limitation) altering
                or modifying any of Our Intellectual Property, causing any of
                Our Intellectual Property to be framed or embedded in another
                website or platform, or creating derivative works from Our
                Intellectual Property.
              </ListItem>
            </OrderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Content you upload
          </Heading>
          <Text>
            We encourage you to interact with our Site! You may be permitted to
            post, upload, publish, submit or transmit relevant information and
            content (User Content) on our Site. If you make any User Content
            available on or through our Site, you grant to us a worldwide,
            irrevocable, perpetual, non-exclusive, transferable, royalty-free
            licence to use the User Content, with the right to use, view, copy,
            adapt, modify, distribute, license, transfer, communicate, publicly
            display, publicly perform, transmit, stream, broadcast or access
            such User Content on, through or by means of our Site. You agree
            that you are solely responsible for all User Content that you make
            available on or through our Site. You represent and warrant that:
            <OrderedList>
              <ListItem listStyleType="lower-latin">
                you are either the sole and exclusive owner of all User Content
                or you have all rights, licences, consents and releases that are
                necessary to grant to us the rights in such User Content (as
                contemplated by these Terms); and
              </ListItem>
              <ListItem listStyleType="lower-latin">
                neither the User Content nor the posting, uploading,
                publication, submission or transmission of the User Content or
                our use of the User Content on, through or by means of our Site
                will infringe, misappropriate or violate a third party’s
                intellectual property rights, or rights of publicity or privacy,
                or result in the violation of any applicable law or regulation.
              </ListItem>
              We do not endorse or approve, and are not responsible for, any
              User Content. We may, at any time (at our sole discretion), remove
              any User Content.
            </OrderedList>
          </Text>
          <Heading size="md" textAlign="center">
            Third party sites
          </Heading>
          <Text>
            Our Site may contain links to websites operated by third parties.
            Unless we tell you otherwise, we do not control, endorse or approve,
            and are not responsible for, the content on those websites. We
            recommend that you make your own investigations with respect to the
            suitability of those websites. What happens if we discontinue our
            Site We may, at any time and without notice to you, discontinue our
            Site, in whole or in part. We may also exclude any person from using
            our Site, at any time and at our sole discretion. We are not
            responsible for any Liability you may suffer arising from or in
            connection with any such discontinuance or exclusion.
          </Text>
          <Heading size="md" textAlign="center">
            Warranties and disclaimers
          </Heading>
          <Text>
            To the maximum extent permitted by law, we make no representations
            or warranties about our Site or our Content, including (without
            limitation) that:
            <OrderedList>
              <ListItem listStyleType="lower-latin">
                they are complete, accurate, reliable, up-to-date and suitable
                for any particular purpose;
              </ListItem>
              <ListItem listStyleType="lower-latin">
                access will be uninterrupted, error-free or free from viruses;
                or
              </ListItem>
              <ListItem listStyleType="lower-latin">
                our Site will be secure.
              </ListItem>
            </OrderedList>
            You read, use and act on our Site and our Content at your own risk.
          </Text>
          <Heading size="md" textAlign="center">
            Our liability is limited
          </Heading>
          <Text>
            To the maximum extent permitted by law, we are not responsible for
            any loss, damage or expense, howsoever arising, whether direct or
            indirect and/or whether present, unascertained, future or contingent
            (Liability) suffered by you or any third party, arising from or in
            connection with your use of Our Intellectual Property and/or any
            inaccessibility of, interruption to or outage of our Site and/or any
            loss or corruption of data and/or the fact that our Content is
            incorrect, incomplete or out-of-date.
          </Text>
          <Heading size="md" textAlign="center">
            Indemnity
          </Heading>
          <Text>
            To the maximum extent permitted by law, you must indemnify us, and
            hold us harmless, against any Liability suffered or incurred by us
            arising from or in connection with your use of our Site or any
            breach of these Terms or any applicable laws by you. This indemnity
            is a continuing obligation, independent from the other obligations
            under these Terms, and continues after these Terms end. It is not
            necessary for us to suffer or incur any Liability before enforcing a
            right of indemnity under these Terms.
          </Text>
          <Heading size="md" textAlign="center">
            Terminating these terms
          </Heading>
          <Text>
            These Terms are effective until terminated by us, which we may do at
            any time and without notice to you. In the event of termination, all
            restrictions imposed on you by these Terms and limitations of
            liability set out in these Terms will survive.
          </Text>
          <Heading size="md" textAlign="center">
            Dealing with a problem
          </Heading>
          <Text>
            If you would like to give us feedback, please contact us – we
            appreciate your input. In the event of any dispute arising from, or
            in connection with, these Terms (Dispute), the party claiming there
            is a Dispute must give written notice to the other party setting out
            the details of the Dispute and proposing a resolution. Within 7 days
            after receiving the notice, the parties must, by someone with
            authority to reach a resolution, meet at least once to attempt to
            resolve the Dispute or agree on the method of resolving the Dispute
            by other means, in good faith. All aspects of every such conference,
            except the fact of the occurrence of the conference, will be
            privileged. If the parties do not resolve the Dispute, or (if the
            Dispute is not resolved) agree on an alternate method to resolve the
            Dispute, within 21 days after receipt of the notice, the Dispute may
            be referred by either party (by notice in writing to the other
            party) to litigation. Nothing in this clause will operate to prevent
            a party from seeking urgent injunctive or equitable relief from a
            court of appropriate jurisdiction.
          </Text>
          <Heading size="md" textAlign="center">
            If a part of these Terms isn’t right
          </Heading>
          <Text>
            If a provision of these Terms is held to be void, invalid, illegal
            or unenforceable, that provision must be read down as narrowly as
            necessary to allow it to be valid or enforceable. If it is not
            possible to read down a provision (in whole or in part), that
            provision (or that part of that provision) is severed from these
            Terms without affecting the validity or enforceability of the
            remainder of that provision or the other provisions in these Terms.
          </Text>
          <Heading size="md" textAlign="center">
            Which laws govern these terms of use
          </Heading>
          <Text>
            Your use of our Site and these Terms are governed by the laws of New
            South Wales. You irrevocably and unconditionally submit to the
            exclusive jurisdiction of the courts operating in New South Wales
            and any courts entitled to hear appeals from those courts and waive
            any right to object to proceedings being brought in those courts.
            Our Site may be accessed throughout Australia and overseas. We make
            no representation that our Site complies with the laws (including
            intellectual property laws) of any country outside Australia. If you
            access our Site from outside Australia, you do so at your own risk
            and are responsible for complying with the laws of the jurisdiction
            where you access our Site.
          </Text>
          <Text>
            For any questions and notices, please contact us at: API Software
            Pty Ltd t/a Canyou Software ABN 57 650 764 195 Email:
            support@canyou.com.au
          </Text>
          <small>Last update: 7 June 2021 © </small>
        </VStack>
      </Flex>
    </Layout>
  );
};

export default Terms;
